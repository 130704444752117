import { GraphContainer } from '@components';
import { groupBy, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getGraphColor } from '@utils/helpers/colors.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React, { useEffect, useRef, useState } from 'react';

import './graphstyles.css';

export function TemperatureControlAppGraphContainer(props) {
    const [curCirculation, setCurCirculation] = useState(null);
    const [allData, setAllData] = useState([]);

    const linkRef = useRef();

    useEffect(() => {
        setAllData(props.data);
    }, [props.data]);

    useEffect(() => {
        if (isNullOrUndefined(props.circulations)) return;

        const firstCirculation = props.circulations[0];
        if (!isNullOrUndefined(firstCirculation)) {
            setCurCirculation(firstCirculation.id);
        }
    }, [props.circulations]);

    const onItemClick = (id) => {
        setCurCirculation(id);
    };

    const onGroupClick = (id) => {
        setCurCirculation(id);
    };

    const onDownloadCsv = (data) => {
        const csvData = [];
        csvData.push('Sensor;Measurement;Value;Timestamp\n');
        data.forEach((sensor) => {
            sensor.datapoints.forEach((dp) => {
                csvData.push(
                    `"${sensor.name}";name;${dp.y
                        .toFixed(2)
                        .replace('.', ',')};${dateToString(
                        dp.x,
                        props.user.timeZone,
                        'de-DE',
                        true
                    ).replace(',', '')}\n`
                );
            });
        });
        const csvBlob = new Blob(csvData, { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(csvBlob);
        linkRef.current.href = url;
        linkRef.current.download = 'chart-data.csv';
        linkRef.current.click();
    };

    const processTemperatureMeasurements = (measurement) => {
        const numberMatch = measurement.match(/\d+$/);
        return numberMatch ? ` - P${numberMatch[0]}` : '';
    };

    const addSeriesData = (data, index) => {
        const series = {
            color: getGraphColor(index, 1),
            datapoints: [],
        };
        for (let dt of data) {
            if (dt.circulationId !== curCirculation) continue;
            series.datapoints.push({
                x: dt.timestamp,
                y: dt.value,
                strangId: dt.strangId,
                measurement: dt.measurement,
            });
            series.name = `${dt.strangName}${processTemperatureMeasurements(
                dt.measurement
            )}`;
        }
        return series;
    };

    const series = [];
    const dataBySensorId = groupBy(allData, 'sensorId');
    let index = 0;
    for (let key in dataBySensorId) {
        const dataSensor = dataBySensorId[key];
        const sensorDataByMeasurement = groupBy(dataSensor, 'measurement');
        for (let measurement in sensorDataByMeasurement) {
            series.push(
                addSeriesData(sensorDataByMeasurement[measurement], index++)
            );
        }
    }

    return (
        <>
            <a ref={linkRef} style={{ display: 'none' }}></a>
            <GraphContainer
                graph={props.graph}
                user={props.user}
                series={series}
                onDownloadCsv={onDownloadCsv}
                loading={props.loading}
                type="temperatureControl"
                site={props.site}
                curCirculation={curCirculation}
                circulations={props.circulations}
                protected={props.protected}
                onGroupClick={onGroupClick}
                onItemClick={onItemClick}
            />
        </>
    );
}
