import { GraphContainer } from '@components';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getGraphColor } from '@utils/helpers/colors.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React, { useEffect, useRef, useState } from 'react';

import './graphstyles.css';

export function TemperatureAppGraphContainer(props) {
    const [allData, setAllData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [curGroupDisplayed, setCurGroupDisplayed] = useState(-1);

    const linkRef = useRef();

    useEffect(() => {
        setGraphData(props.data);
        setAllData(props.data);
        onItemClick(
            curGroupDisplayed === 0 ? undefined : curGroupDisplayed,
            props.data
        );
    }, [props.data]);

    const getDataFromGroup = (gid, data, dt = null) => {
        const applicableMps = props.mps
            ?.filter((mp) => mp.group.id === gid)
            ?.map((mp) => mp.id);
        if (isNullOrUndefined(dt)) {
            data.value = [
                ...data.value,
                ...allData?.filter((d) =>
                    applicableMps.includes(parseInt(d.mpId, 10))
                ),
            ];
        } else {
            data.value = [
                ...data.value,
                ...dt.filter((d) =>
                    applicableMps.includes(parseInt(d.mpId, 10))
                ),
            ];
        }
        const newGroups = props.groups?.filter((g) => g.parent?.id === gid);
        newGroups?.forEach((newGroup) => {
            getDataFromGroup(newGroup.id, data);
        });
    };

    const onItemClick = (id, dt = null) => {
        if (id === -1) {
            return;
        }
        setCurGroupDisplayed(id);
        const gData = { value: [] };
        getDataFromGroup(id, gData, dt);
        setGraphData(gData.value);
    };

    const onGroupClick = (id) => {
        const gData = { value: [] };
        setCurGroupDisplayed(id);
        getDataFromGroup(id === 0 ? undefined : id, gData);
        setGraphData(gData.value);
    };

    const onDownloadCsv = (data) => {
        const csvData = [];
        csvData.push('Measurement Point;Value;Timestamp\n');
        data.forEach((sensor) => {
            sensor.datapoints.forEach((dp) => {
                csvData.push(
                    `"${sensor.name}";${dp.y
                        .toFixed(2)
                        .replace('.', ',')};${dateToString(
                        dp.x,
                        props.user.timeZone,
                        'de-DE',
                        true
                    ).replace(',', '')}\n`
                );
            });
        });
        const csvBlob = new Blob(csvData, { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(csvBlob);
        linkRef.current.href = url;
        linkRef.current.download = 'chart-data.csv';
        linkRef.current.click();
    };

    const addSeriesData = (data, mpName, index) => {
        let serie = {
            name: mpName,
            color: getGraphColor(index, 1),
            datapoints: [],
        };
        for (let dt of data) {
            if (dt.measurement.startsWith(props.graph?.measurement)) {
                serie.datapoints.push({ x: dt.timestamp, y: dt.value });
            }
        }
        return serie;
    };

    const series = [];
    const data = graphData;
    let i = 0;
    data?.sort((a, b) => parseInt(a.mpId, 10) - parseInt(b.mpId, 10));
    data?.forEach((line) => {
        const mpDescr =
            props.mps?.find((m) => m.id === parseInt(line.mpId, 10))
                ?.description ?? line.mpId;
        let addLineData = true;
        if (props.graph?.measurement === 'temperature') {
            // handle data with more than one probe
            let tempProbesData = line.values?.filter((v) =>
                new RegExp(/temperature\d/gm).test(v.measurement)
            );
            if (tempProbesData?.length > 0) {
                tempProbesData = line.values?.filter((v) =>
                    v.measurement.startsWith('temperature')
                );
                const uniqueTemps = [
                    ...new Set(tempProbesData.map((v) => v.measurement)),
                ];
                if (uniqueTemps?.length > 0) {
                    const numberOfProbes = uniqueTemps?.length ?? 0;
                    for (let j = 1; j <= numberOfProbes; j++) {
                        const serie = addSeriesData(
                            tempProbesData.filter(
                                (v) => v.measurement === uniqueTemps[j - 1]
                            ),
                            `${mpDescr} - P${j}`,
                            i
                        );
                        series.push(serie);
                        i += 1;
                    }
                    if (numberOfProbes > 0) {
                        addLineData = false;
                    }
                }
            }
        }
        if (addLineData) {
            const serie = addSeriesData(line.values, mpDescr, i);
            series.push(serie);
            i += 1;
        }
    });

    return (
        <>
            <a ref={linkRef} style={{ display: 'none' }}></a>
            <GraphContainer
                graph={props.graph}
                user={props.user}
                series={series}
                onDownloadCsv={onDownloadCsv}
                groups={props.groups}
                loading={props.loading}
                protected={props.protected}
                onGroupClick={onGroupClick}
                onItemClick={onItemClick}
            />
        </>
    );
}
